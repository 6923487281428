import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

type PentResult = {
  id: number;
  partnership: string;
  points: number;
  runs: number;
};

const Pentathlon = (): JSX.Element => {
  const [height, setHeight] = useState<string>("600");
  const [results, setResults] = useState<PentResult[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setHeight(event.target.value as string);
  };

  useEffect(() => {
    loadPentResults(height);

    const interval = setInterval(() => loadPentResults(height), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [height]);

  const loadPentResults = async (height: string) => {
    const response = await axios.get(
      "https://api.easyagility.co.uk/pentathlon-results?height=" + height
    );

    if (response.data) setResults(response.data);
    else setResults([]);
  };

  return (
    <Box>
      <Typography variant="h5">Pentathlon Overall Results</Typography>
      <Typography marginTop={2} fontWeight={"italics"}>
        Please note that these are live results, they are not official until
        they have been confirmed by the team management.
      </Typography>
      <Select
        fullWidth
        sx={{ marginTop: 2 }}
        value={height}
        onChange={handleChange}
      >
        <MenuItem value={"600"}>600</MenuItem>
        <MenuItem value={"500"}>500</MenuItem>
        <MenuItem value={"400"}>400</MenuItem>
        <MenuItem value={"300"}>300</MenuItem>
        <MenuItem value={"250"}>250</MenuItem>
      </Select>

      <Box marginTop={2}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Place</th>
              <th>Partnership</th>
              <th>Points</th>
              <th>Runs</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{result.partnership}</td>
                <td>{result.points}</td>
                <td>{result.runs}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Pentathlon;
