import React from "react";
import Results from "../Results";

const LandingPage = () => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Results />
      </div>
    </div>
  );
};

export default LandingPage;
