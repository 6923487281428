import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

type League = {
  id: number;
  name: string;
  height_grades: any;
};

type Result = {
  position: number;
  partnership_name: string;
  points: number;
  total_faults: number;
  total_time: number;
};

const Leagues = (): JSX.Element => {
  const [league, setLeague] = useState<League>({} as League);
  const [height, setHeight] = useState<string>("300");
  const [results, setResults] = useState<any>();

  var specifiedDateTime = new Date();
  specifiedDateTime.setFullYear(2024); // Set the year
  specifiedDateTime.setMonth(1); // Set the month (months are zero-indexed, so 1 represents February)
  specifiedDateTime.setDate(17); // Set the day
  specifiedDateTime.setHours(17); // Set the hours
  specifiedDateTime.setMinutes(0); // Set the minutes

  var currentDateTime = new Date();

  const leagueId = 1;

  const getLeague = async () => {
    const response = await axios.get(
      "https://api.easyagility.co.uk/leagues/" + leagueId
    );

    setLeague(response.data);

    getResults(response.data, "300");
  };

  const getResults = async (league: League, height: string) => {
    const results: any = {};

    if (league?.height_grades) {
      const grades = league.height_grades[height];

      for (const grade of grades) {
        const response = await axios.get(
          `https://api.easyagility.co.uk/leagues/${leagueId}/points?height=${encodeURIComponent(
            height
          )}&grades=${grade?.join(",")}`
        );

        results[grade?.join(",")] = response.data as Result[];
      }

      setResults(results);
    }
  };

  useEffect(() => {
    getLeague();
  }, [leagueId]);

  useEffect(() => {
    if (league.height_grades) {
      getResults(league, height);
    }
  }, [league, height]);

  return (
    <Box>
      <Typography fontSize={36}>{league.name}</Typography>
      {league.height_grades && (
        <Box marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id={`height-select-label`}>Select A Height</InputLabel>
            <Select
              labelId={`height-select-label`}
              id={`height-select`}
              label="Select A Height"
              name="select_height"
              fullWidth
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            >
              {Object.keys(league.height_grades).map((height, index) => (
                <MenuItem key={index} value={height}>
                  {height}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography fontWeight={"bold"} marginTop={2}>
            Please note, in the event of a tie, places have been ordered first
            on the fewest faults, then on time.
          </Typography>
          {league.height_grades[height] &&
            league.height_grades[height].map((grade: any, index: number) => (
              <Box key={index} marginTop={2}>
                <Typography>Grades: {grade.join(", ")}</Typography>
                <Box marginTop={2} marginBottom={5}>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Place</th>
                        <th>Partnership</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results && results[grade.join(",")] ? (
                        results[grade.join(",")]?.map(
                          (result: Result, index: number) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{result.partnership_name}</td>
                              <td>{result.points}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Leagues;
