import {
  Box,
  Button,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ResultsProps } from "./types";
import PhoenixLogo from "../../PhoenixLogo.png";
import Lacey from "../../Lacey.jpeg";
import useWindowDimensions from "../../utils/useWindowDimensions";
import WAO from "../../images/wao.jpg";
import RingPlan from "./RingPlan/component";

export type Entry = {
  id: number;
  queued_at?: string;
  partnership?: string;
  time?: number;
  time_without_faults?: number;
  nfc_run?: boolean;
  eliminated?: boolean;
  total_faults?: number;
  run_data?: string[];
};

export type Show = {
  name: string;
  date: Date;
  venue: string;
  classes: C[];
};

function srcset(image: any, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

type C = {
  id: number;
  name: string;
  height_grades: any;
  status?: string;
  metadata?: any;
  placings?: any;
  trophies?: any;
};

type status = {
  last_runner?: string;
  next_runner?: string;
  left_to_run?: number;
};

const Results = ({ showID }: ResultsProps): JSX.Element => {
  if (!showID) showID = 29;

  var specifiedDateTime = new Date();
  specifiedDateTime.setFullYear(2024); // Set the year
  specifiedDateTime.setMonth(1); // Set the month (months are zero-indexed, so 1 represents February)
  specifiedDateTime.setDate(17); // Set the day
  specifiedDateTime.setHours(17); // Set the hours
  specifiedDateTime.setMinutes(0); // Set the minutes

  var currentDateTime = new Date();

  const { width } = useWindowDimensions();

  const [classValue, setClassValue] = useState<number>(0);
  const [height, setHeight] = useState<string>("");
  const [heightGrades, setHeightGrades] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [heights, setHeights] = useState<string[]>([]);
  const [show, setShow] = useState<Show>({} as Show);
  const [isPentathlon, setIsPentathlon] = useState<boolean>(false);
  const [showRingPlan, setShowRingPlan] = useState<boolean>(true);
  const [status, setStatus] = useState<status>({} as status);

  useEffect(() => {
    getShow();
  }, [showID]);

  useEffect(() => {}, [height]);

  const getShow = async () => {
    const response = await axios.get(
      `https://api.easyagility.co.uk/shows/${showID}`
    );

    setShow(response.data);
    setClassValue(response.data?.classes[0].id);
    setHeightGrades(response.data?.classes[0].height_grades);
    if (response.data?.classes[0].height_grades) {
      const heightGrades = response.data?.classes[0].height_grades as any;
      const heights = Object.keys(heightGrades);
      setHeights(heights);
      setHeight(heights[0]);
    }
  };

  const [results, setResults] = useState<any>();

  useEffect(() => {
    console.log(classValue);

    if (showID && !showRingPlan) {
      getResults(classValue, showID, height);

      if (
        show.classes
          ?.find((c) => c.id === classValue)
          ?.name.toLowerCase()
          .includes("pentathlon")
      ) {
        setIsPentathlon(true);
      } else {
        setIsPentathlon(false);
      }

      const interval = setInterval(
        () => getResults(classValue, showID || 0, height),
        10000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [classValue, height, showRingPlan]);

  const getResults = async (
    classValue: number,
    showID: number,
    height: string
  ) => {
    const results: any = {};
    console.log(classValue, showID, height);

    if (heightGrades) {
      const grades = heightGrades[height];

      for (const grade of grades) {
        const response = await axios.get(
          `https://api.easyagility.co.uk/shows/${showID}/classes/${classValue}/results?height=${encodeURIComponent(
            height
          )}&grades=${grade?.join(",")}`
        );

        results[grade?.join(",")] = response.data as Entry[];

        const statusResponse = await axios.get(
          `https://api.easyagility.co.uk/shows/${showID}/classes/${classValue}/status?height=${encodeURIComponent(
            height
          )}&grades=${grade?.join(",")}`
        );

        setStatus(statusResponse.data as status);
      }

      console.log(results);

      setResults(results);
    }
  };

  const columns = [
    {
      field: "position",
      headerName: "",
      flex: 0.3,
      minWidth: "30",
      renderCell: (params: any) => {
        const clss = show?.classes?.find((c) => c.id === classValue);
        const grades = params.row.grades;

        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.row.eliminated === true ? (
              <>&nbsp;</>
            ) : clss &&
              clss.status === "closed" &&
              clss.placings &&
              clss.placings[height] &&
              clss.placings[height][grades] &&
              clss.placings[height][grades] >= params.value ? (
              <>{`${params.value} ${
                clss.trophies[height][grades] >= params.value ? "🏆" : "🏵️"
              }`}</>
            ) : clss &&
              clss.status === "closed" &&
              clss.placings &&
              clss.placings[height] &&
              clss.placings[height][grades] &&
              clss.placings[height][grades] < params.value &&
              params.row.total_faults === 0 ? (
              "CR"
            ) : clss &&
              clss.status === "closed" &&
              clss.placings &&
              clss.placings[height] &&
              clss.placings[height][grades] &&
              clss.placings[height][grades] < params.value ? (
              ""
            ) : (
              params.value
            )}
          </Box>
        );
      },
    },
    {
      field: "partnership",
      headerName: "Entry",
      flex: 1.0,
      minWidth: 80,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "total_points",
      headerName: "Game Points",
      flex: 0.6,
      minWidth: 60,
      hide:
        !show.classes
          ?.find((c) => c.id === classValue)
          ?.name.toLowerCase()
          .includes("snooker") &&
        !show.classes
          ?.find((c) => c.id === classValue)
          ?.name.toLowerCase()
          .includes("gamblers"),
    },
    {
      field: "time",
      headerName: isPentathlon ? "Time (Inc Faults)" : "Time",
      flex: 0.4,
      minWidth: 40,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.row.eliminated === true ? "E" : params.value}
          </Box>
        );
      },
    },
    {
      field: "time_without_faults",
      headerName: "Time (W/o Faults)",
      flex: 0.8,
      minWidth: 80,
      hide: !isPentathlon,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.row.eliminated === true ? "E" : params.value}
          </Box>
        );
      },
    },
    {
      field: "total_faults",
      headerName: "F",
      minWidth: 40,
      flex: 0.4,
      renderCell: (params: any) => {
        return <>{`${Math.round(params.value * 1000) / 1000} `}</>;
      },
      hide:
        show.classes
          ?.find((c) => c.id === classValue)
          ?.name.toLowerCase()
          .includes("snooker") ||
        show.classes
          ?.find((c) => c.id === classValue)
          ?.name.toLowerCase()
          .includes("gamblers"),
    },
    {
      field: "run_data",
      headerName: "",
      flex: 0.6,
      minWidth: 60,
      hide: true,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.value?.join(", ")}
          </Box>
        );
      },
    },
  ];

  return (
    <Grid marginTop={1} container spacing={1}>
      {/* <Grid xs={12}>
        <Typography marginBottom={2}>{show.name}</Typography>
      </Grid> */}
      {showRingPlan ? (
        <>
          {/* <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                window.location.href =
                  "https://s3.eu-west-2.amazonaws.com/easyagility.co.uk/schedules/Running+Orders+Final.pdf";
              }}
            >
              View Running Orders
            </Button>
          </Grid> */}
          <RingPlan
            show={show}
            setHeightGrades={setHeightGrades}
            showName={show.name}
            setShowRingPlan={setShowRingPlan}
            showId={showID || 0}
            setHeight={setHeight}
            setClass={setClassValue}
          />
        </>
      ) : (
        <>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={`class-select-label`}>Select A Class</InputLabel>
              <Select
                labelId={`class-select-label`}
                id={`class-select`}
                label="Select A Class"
                name="select_class"
                value={classValue}
                onChange={(e) => {
                  setLoading(true);
                  console.log(e.target.value);
                  setClassValue(e.target.value as any);
                  const heightGrades = show.classes.find(
                    (c) => c.id === e.target.value
                  )?.height_grades;

                  setHeightGrades(heightGrades);
                  const heights = Object.keys(heightGrades);
                  setHeights(heights);
                  setHeight(heights[0]);
                  setLoading(false);
                }}
                fullWidth
              >
                {show?.classes?.map((c: C, index: number) => (
                  <MenuItem key={index} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={`height-select-label`}>
                Select A Height
              </InputLabel>
              <Select
                labelId={`height-select-label`}
                id={`height-select`}
                label="Select A Height"
                name="select_height"
                fullWidth
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              >
                {heights.map((height, index) => (
                  <MenuItem key={index} value={height}>
                    {height}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  onClick={() => setShowRingPlan(true)}
                  variant="outlined"
                  sx={{
                    bgcolor: "rgb(229, 246, 253)",
                    color: "#00395A",
                    borderColor: "#00395A",
                    marginBottom: 1,
                    textTransform: "none",
                  }}
                >
                  Ring Plan
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography marginBottom={2}>
                  {show.name} -{" "}
                  {show?.classes?.find((c) => c.id === classValue)?.name} -
                  Height: {height}. Class Status:{" "}
                  {show?.classes
                    ?.find((c) => c.id === classValue)
                    ?.status?.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {show?.classes?.find((c) => c.id === classValue)?.metadata[
                  height
                ]?.course_time && (
                  <Typography>
                    Course Time:{" "}
                    {show?.classes?.find((c) => c.id === classValue)?.metadata[
                      height
                    ]?.course_time || ""}{" "}
                    seconds
                  </Typography>
                )}
                {show?.classes?.find((c) => c.id === classValue)?.metadata[
                  height
                ]?.course_distance && (
                  <Typography>
                    Course Distance:{" "}
                    {show?.classes?.find((c) => c.id === classValue)?.metadata[
                      height
                    ]?.course_distance || ""}{" "}
                    metres
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {status.last_runner && showID === 25 && (
            <Grid item xs={12}>
              <Box textAlign={"left"}>
                <Typography>Last Runner: {status.last_runner}</Typography>
              </Box>
            </Grid>
          )}
          {status.next_runner && showID === 25 && (
            <Grid item xs={12}>
              <Box textAlign={"left"}>
                <Typography>Next Runner: {status.next_runner}</Typography>
              </Box>
            </Grid>
          )}
          {status.left_to_run && showID === 25 && (
            <Grid item xs={12}>
              <Box textAlign={"left"}>
                <Typography>Dogs left to run: {status.left_to_run}</Typography>
              </Box>
            </Grid>
          )}
          <>
            {loading ? (
              <></>
            ) : (
              <>
                {results &&
                  Object.keys(results).map((grades, index) => (
                    <Grid item xs={12}>
                      <Box
                        paddingTop={1}
                        bgcolor={"rgb(229, 246, 253)"}
                        key={index}
                      >
                        <Typography marginBottom={2}>
                          Grade{grades.length === 1 ? "" : "s"}{" "}
                          {grades.length === 1
                            ? grades[0]
                            : `${grades[0]}-${grades[grades.length - 1]}`}
                        </Typography>
                        <Typography>
                          {show?.classes?.find((c) => c.id === classValue)
                            ?.placings[height] &&
                            show?.classes?.find((c) => c.id === classValue)
                              ?.placings[height][grades] &&
                            `Number of placings: ${
                              show?.classes?.find((c) => c.id === classValue)
                                ?.placings[height][grades]
                            }`}
                        </Typography>
                        {/* <Typography>
                          {show?.classes?.find((c) => c.id === classValue)
                            ?.trophies[height] &&
                            show?.classes?.find((c) => c.id === classValue)
                              ?.trophies[height][grades] &&
                            `Number of trophies: ${
                              show?.classes?.find((c) => c.id === classValue)
                                ?.trophies[height][grades]
                            }`}
                        </Typography> */}
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            ".MuiDataGrid-cell:focus": {
                              outline: "none",
                            },
                          }}
                          hideFooter={true}
                          hideFooterPagination={true}
                          components={{
                            NoRowsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No results available yet
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No results yet
                              </Stack>
                            ),
                          }}
                          {...({
                            columns: columns,
                            rows: results[grades] || [],
                          } as any)}
                          autoHeight
                        />
                      </Box>
                    </Grid>
                  ))}
              </>
            )}
          </>
        </>
      )}
    </Grid>
  );
};

export default Results;
